import { useHook, useMutationHook } from "../utils/use-hook";
import { mutationFetcher } from "../utils/default-fetcher";
export const fetcher = mutationFetcher;
const fn = (provider)=>{
    var _provider_cart;
    return (_provider_cart = provider.cart) == null ? void 0 : _provider_cart.useUpdateItem;
};
const useUpdateItem = (input)=>{
    const hook = useHook(fn);
    return useMutationHook({
        fetcher,
        ...hook
    })(input);
};
export default useUpdateItem;
