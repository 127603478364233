import { FC, useEffect, useState } from 'react'
import Link from 'next/link'
import s from './Navbar.module.scss'
import NavbarRoot from './NavbarRoot'
import { Container } from '@components/ui'
import { UserNav } from '@components/common'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { ShopNavigationColumn } from '@components/common/Layout/Layout'
import { useTranslation } from 'next-i18next'
import { getStrapiMedia } from '@utils/strapi'
import delve from 'dlv'

interface Link {
  href: string
  label: string
}

interface NavbarProps {
  links?: ShopNavigationColumn[]
}

const Navbar: FC<NavbarProps> = ({ links }) => {
  const { events } = useRouter()
  const [navbar, setNavbar]: any = useState(null)
  const { t } = useTranslation('common')
  const router = useRouter()
  const [language, setLanguage]: any = useState(router.locale)

  function toggleNavbar() {
    setNavbar(navbar ? null : s.active)
  }

  function closeNavbar() {
    navbar && setNavbar(null)
  }

  // Make sure nav is closed when navigating
  // Never open it this way, but if its open, close it
  useEffect(() => {
    events.on('routeChangeStart', closeNavbar)
    return () => {
      events.off('routeChangeStart', closeNavbar)
    }
  }, [closeNavbar, events])

  const changeCase = (str: string, isLower = false) =>
    isLower ? str.toLowerCase() : str.toUpperCase()

  /*TODO get all pages from server, and store localized slugs in array dynamically */
  const dynamicPageSlugs = [
    {
      en: 'about-us',
      de: 'ueber-uns',
    },
    {
      en: 'reviews',
      de: 'erfahrungsberichte',
    },
    {
      en: 'imprint',
      de: 'impressum',
    },
    {
      en: 'about-us',
      de: 'ueber-uns',
    },
    {
      en: 'contact',
      de: 'kontakt',
    },
  ]

  const onChangeLanguage = (lang: string) => async (e: any) => {
    setLanguage(lang);
    window.location.href = `/${lang}`;
    return;
  }

  return (
    <NavbarRoot>
      <div>
        <Container clean className={[s.navContainer, navbar].join(' ')}>
          <Link legacyBehavior href="/">
            <a className={s.logo} aria-label="Logo">
              <Image
                className={s.img}
                src="/logo.svg"
                alt="Hanferei"
                width={200}
                height={91}
                priority={false}
                quality="85"
              />
            </a>
          </Link>
          <nav className={s.mainNav}>
            <ul className={s.siteMenu}>
              <li>
                <a href={'/' + language + '/' + changeCase(t('search'), true)}>
                  {t('products')}
                </a>
                <div className={s.subMenu}>
                  <div className={s.expandable}>
                    {' '}
                    {links?.map((link: ShopNavigationColumn, i: any) => {
                      return (
                        <ul key={i}>
                          <li className={s.link}>
                            {/* KEEP BELOW PART OUT, AS WE DO NOT HAVE
                            MULTI COLUMN SUPPORT CURRENTLY FOR THIS
                            PROJECT.
                        */}
                            {/*{link.href.includes('shop') ? (
                          <>
                            <Link legacyBehavior href={`/${link.href}`}>
                              <a>
                                <span className={s.navIcon}>
                                  <Cart></Cart>
                                </span>{' '}
                                {link.label}
                              </a>
                            </Link>
                          </>
                        ) : (
                          <Link legacyBehavior href={`/search${link.href}`}>
                            <a>{link.label}</a>
                          </Link>
                        )}*/}
                            {link.ShopNavigationItem?.length > 0 ? (
                              <ul>
                                {link.ShopNavigationItem.map(
                                  (subLink, x: any) => {
                                    return (
                                      <li key={x} className={s.link}>
                                        <Link
                                          legacyBehavior
                                          href={`/search/${subLink.href}`}
                                        >
                                          <a className="flex flex-row">
                                            <div className="my-auto">
                                              <Image
                                                src={
                                                  getStrapiMedia(
                                                    delve(
                                                      subLink.Icon,
                                                      'data.attributes.url'
                                                    )
                                                  ) || ''
                                                }
                                                alt={
                                                  delve(
                                                    subLink.Icon,
                                                    'data.attributes.alternativeText'
                                                  ) || ''
                                                }
                                                width={20}
                                                height={20}
                                                priority={true}
                                                quality="85"
                                                className="object-contain mr-2 aspect-square"
                                              />
                                            </div>
                                            <span className="my-auto">
                                              {subLink.label}
                                            </span>
                                          </a>
                                        </Link>
                                      </li>
                                    )
                                  }
                                )}
                              </ul>
                            ) : null}
                          </li>
                        </ul>
                      )
                    })}
                  </div>
                </div>
              </li>
              <li>
                <a href={'/' + language + '/' + changeCase(t('news'), true)}>
                  {t('news')}
                </a>
              </li>
              <li>
                <a href={'/' + language + '/' + changeCase(t('reviews'), true)}>
                  {t('reviews')}
                </a>
              </li>
              <li>
                <a
                  href={
                    '/' + language + '/' + changeCase(t('slug-about-us'), true)
                  }
                >
                  {t('about-us')}
                </a>
              </li>
            </ul>

            <ul className={s.langMenu}>
              <li>
                <button onClick={onChangeLanguage('de')}>
                  <span className={language === 'de' ? 'text-accent-6' : ''}>
                    DE
                  </span>
                </button>
                <span className="mx-2">|</span>
                <button onClick={onChangeLanguage('en')}>
                  <span className={language === 'en' ? 'text-accent-6' : ''}>
                    EN
                  </span>
                </button>
              </li>
            </ul>
          </nav>
          <div className={s.navControls}>
            <div className={s.cartButton}>
              <UserNav />
            </div>
            <div
              className={s.toggle}
              onClick={() => {
                toggleNavbar()
              }}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Container>
      </div>
    </NavbarRoot>
  )
}

export default Navbar
