import Link from 'next/link'
import s from './Header.module.scss'
import Image from 'next/image'
import { FC, useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import { getStrapiMedia } from '@utils/strapi'
import delve from 'dlv'
import {useTranslation} from "next-i18next";

interface Link {
  href: string
  label: string
}

interface HeaderProps {
  links?: Link[]
}

const Header: FC<HeaderProps> = ({ links }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const { t } = useTranslation('common')

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
      renderMode: 'performance',
      loop: true,
      mode: 'snap',
      breakpoints: {
        '(min-width: 768px)': {
          slides: { perView: 1, spacing: 100 },
        },
        '(min-width: 1024px)': {
          slides: { perView: 1, spacing: 100 },
        },
      },
      defaultAnimation: {
        duration: 3000,
      },
      slides: { perView: 1, spacing: 100 },
    }, [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ]
  )

  return (
    <div className={s.headerBar}>
      <div ref={sliderRef} className="keen-slider">
        <div key={1} className="keen-slider__slide" style={{ display: 'flex' }}>
          <span style={{ margin: 'auto' }}>{t("eigener-bio-hanfanbau")}</span>
        </div>
        <div key={2} className="keen-slider__slide" style={{ display: 'flex' }}>
          <span style={{ margin: 'auto' }}>{t("langjaehrige-erfahrung-mit-cbd")}</span>
        </div>
        <div key={3} className="keen-slider__slide" style={{ display: 'flex' }}>
          <span style={{ margin: 'auto' }}>{t("tausende-kunden-sind-ueberzeugt")}</span>
        </div>
        <div key={4} className="keen-slider__slide" style={{ display: 'flex' }}>
          <span style={{ margin: 'auto' }}>{t("zahlreiche-stammkunden")}</span>
        </div>
        <div key={5} className="keen-slider__slide" style={{ display: 'flex' }}>
          <span style={{ margin: 'auto' }}>{t("oesterreichische-bio-qualitaet")}</span>
        </div>
      </div>
    </div>
  )
}

export default Header
