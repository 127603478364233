const Instagram = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.314,6.289a4.264,4.264,0,1,0,4.264,4.264A4.257,4.257,0,0,0,8.314,6.289Zm0,7.036a2.772,2.772,0,1,1,2.772-2.772A2.777,2.777,0,0,1,8.314,13.325Zm5.433-7.21a.995.995,0,1,1-.995-.995A.992.992,0,0,1,13.747,6.115Zm2.824,1.009A4.922,4.922,0,0,0,15.227,3.64,4.954,4.954,0,0,0,11.743,2.3c-1.373-.078-5.488-.078-6.862,0A4.947,4.947,0,0,0,1.4,3.636,4.938,4.938,0,0,0,.053,7.12c-.078,1.373-.078,5.488,0,6.862A4.922,4.922,0,0,0,1.4,17.466,4.96,4.96,0,0,0,4.881,18.81c1.373.078,5.488.078,6.862,0a4.922,4.922,0,0,0,3.485-1.343,4.954,4.954,0,0,0,1.343-3.485c.078-1.373.078-5.485,0-6.858ZM14.8,15.455a2.806,2.806,0,0,1-1.581,1.581c-1.095.434-3.692.334-4.9.334s-3.811.1-4.9-.334a2.806,2.806,0,0,1-1.581-1.581C1.4,14.36,1.5,11.763,1.5,10.553s-.1-3.811.334-4.9A2.806,2.806,0,0,1,3.412,4.07c1.095-.434,3.692-.334,4.9-.334s3.811-.1,4.9.334A2.806,2.806,0,0,1,14.8,5.651c.434,1.095.334,3.692.334,4.9S15.231,14.364,14.8,15.455Z"
            transform="translate(3.688 1.447)"
            fill="currentColor"/>
    </svg>
  )
}

export default Instagram
