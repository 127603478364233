const Facebook = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.119,10.688l.528-3.439h-3.3V5.018A1.719,1.719,0,0,1,10.286,3.16h1.5V.232A18.292,18.292,0,0,0,9.123,0C6.406,0,4.63,1.647,4.63,4.628V7.249H1.609v3.439H4.63V19H8.347V10.688Z"
            transform="translate(5.303 2.5)"
            fill="currentColor"/>
    </svg>
  )
}

export default Facebook
