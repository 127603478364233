import { FC, useState } from 'react'
import cn from 'clsx'
import Link from 'next/link'
import type { Page } from '@commerce/types/page'
import { Container } from '@components/ui'
import s from './Footer.module.scss'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { Facebook, Instagram } from '@components/icons'
import { ShopNavigationColumn } from '@components/common/Layout/Layout'
import { getStrapiMedia } from '@utils/strapi'
import delve from 'dlv'
import { useRouter } from 'next/router'

interface Props {
  className?: string
  children?: any
  pages?: Page[]
  links: ShopNavigationColumn[]
}

const Footer: FC<Props> = ({ className, links }) => {
  const router = useRouter()
  const rootClassName = cn(s.root, className)
  const [language, setLanguage]: any = useState(router.locale)
  const { t } = useTranslation('common')
  const year = new Date().getFullYear()
  const changeCase = (str: string, isLower = false, encode = true) => {
    if (isLower) {
      str = str.toLowerCase()
    } else {
      str = str.toUpperCase()
    }
    if (encode) {
      str = str.replace(/\s+/g, '-')
    } // replace spaces with - for url's
    return str
  }
  return (
    <>
      <div className={s.iconBar}>
        <Container className={s.row}>
          <div>
            <Image
              src="/icons/green-5-zahlung.png"
              alt="Zahlung Icon"
              width={40}
              height={40}
              priority={false}
              quality="85"
              className="mx-auto mb-3"
            />
            <span className={s.iconCaption}
                  dangerouslySetInnerHTML={{
                    __html: t('secure-data-and-payment'),
                  }}>
            </span>
          </div>
          <div>
            <Image
              src="/icons/green-5-versand.png"
              alt="Versand Icon"
              width={40}
              height={40}
              priority={false}
              quality="85"
              className="mx-auto mb-3"
            />
            <span className={s.iconCaption}
                  dangerouslySetInnerHTML={{
                    __html: t('shipping-within-48-hours'),
                  }}>
            </span>
          </div>
          <div>
            <Image
              src="/icons/green-5-zertifikat.png"
              alt="Zufriedenheitsgarantie Icon"
              width={40}
              height={40}
              priority={false}
              quality="85"
              className="mx-auto mb-3"
            />
            <span className={s.iconCaption}
                  dangerouslySetInnerHTML={{
                    __html: t('happiness-guarantee'),
                  }}>
            </span>
          </div>
        </Container>
      </div>

      <footer className={rootClassName}>
        <Container>
          <div className="flex flex-wrap justify-between px-2 border-b border-accent-7 pb-4 mb-4">
            <Image
              className="border border-accent-7 rounded"
              src="/logo.svg"
              alt="Hanferei"
              width={140}
              height={65}
              priority={false}
              quality="85"
            />
            <div className="flex gap-4 items-center">
              <Link
                legacyBehavior
                href="https://www.facebook.com/seewinklerHANFEREI"
              >
                <a target="_blank" aria-label="Visit facebook">
                  <Facebook className="text-light" />
                </a>
              </Link>
              <Link legacyBehavior href="https://www.instagram.com/hanferei/">
                <a target="_blank" aria-label="Visit Instagram">
                  <Instagram className="text-light" />
                </a>
              </Link>
              <Link
                legacyBehavior
                href="https://www.facebook.com/groups/hanferei"
              >
                <a aria-label="Visit Exchange Group" target="_blank">
                  <Image
                    src="/icons/light-icon-austausch.png"
                    alt={t('Exchange Group')}
                    width={20}
                    height={20}
                    priority={false}
                    quality="85"
                  />
                </a>
              </Link>
            </div>
          </div>
          <div className="text-center md:grid grid-cols-12 gap-4 px-2">
            <div className="grow text-center md:text-left md:col-span-3 lg:col-span-5 lg:flex gap-4 lg:gap-8">
              <ul className={s.productLinkList}>
                <li>
                  <a href={'/' + language + '/' + changeCase(t('news'), true)}>
                    {t('news')}
                  </a>
                </li>
                <li>
                  <a
                    href={'/' + language + '/' + changeCase(t('reviews'), true)}
                  >
                    {t('reviews')}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      '/' + language + '/' + changeCase(t('slug-about-us'), true)
                    }
                  >
                    {t('about-us')}
                  </a>
                </li>
              </ul>
              <ul className={s.productLinkList}>
                <li>
                  <a
                    href={'/' + language + '/' + changeCase(t('search'), true)}
                  >
                    {t('products')}
                  </a>

                  {links?.map((link: ShopNavigationColumn, i: any) => {
                    return (
                      <ul key={i}>
                        <li className={s.link}>
                          {/* KEEP BELOW PART OUT, AS WE DO NOT HAVE
                            MULTI COLUMN SUPPORT CURRENTLY FOR THIS
                            PROJECT.
                        */}
                          {/*{link.href.includes('shop') ? (
                          <>
                            <Link legacyBehavior href={`/${link.href}`}>
                              <a>
                                <span className={s.navIcon}>
                                  <Cart></Cart>
                                </span>{' '}
                                {link.label}
                              </a>
                            </Link>
                          </>
                        ) : (
                          <Link legacyBehavior href={`/search${link.href}`}>
                            <a>{link.label}</a>
                          </Link>
                        )}*/}
                          {link.ShopNavigationItem?.length > 0 ? (
                            <ul>
                              {link.ShopNavigationItem.map(
                                (subLink, x: any) => {
                                  return (
                                    <li key={x} className={s.link}>
                                      <Link
                                        legacyBehavior
                                        href={`/search/${subLink.href}`}
                                      >
                                        <a>
                                          <div className="hidden">
                                            {getStrapiMedia(
                                              delve(
                                                subLink.Icon,
                                                'data.attributes.url'
                                              )
                                            )}
                                          </div>
                                          {subLink.label}
                                        </a>
                                      </Link>
                                    </li>
                                  )
                                }
                              )}
                            </ul>
                          ) : null}
                        </li>
                      </ul>
                    )
                  })}
                </li>
              </ul>
            </div>

            <div className="grid grid-cols-2 my-4 md:my-0 md:flex md:flex-col gap-1 md:col-span-3 lg:col-span-2">
              <div className={s.iconCard}>
                <Image
                  src="/icons/icon-visa.svg"
                  alt={t('creditcard')}
                  width={16}
                  height={16}
                  priority={false}
                  quality="85"
                />
                <div>Visa</div>
              </div>
              <div className={s.iconCard}>
                <Image
                  src="/icons/icon-mastercard.svg"
                  alt={t('creditcard')}
                  width={16}
                  height={16}
                  priority={false}
                  quality="85"
                />
                <div>Mastercard</div>
              </div>

              <div className={s.iconCard}>
                <Image
                  src="/icons/icon-maestro.svg"
                  alt={t('creditcard')}
                  width={16}
                  height={16}
                  priority={false}
                  quality="85"
                />
                <div>Maestro</div>
              </div>

              <div className={s.iconCard}>
                <Image
                  src="/icons/icon-sofort.svg"
                  alt={t('sofort')}
                  width={16}
                  height={16}
                  priority={false}
                  quality="85"
                />
                <div>{t('sofort')}</div>
              </div>
            </div>
            <div className="md:flex flex-row text-accent-4 md:col-span-6 lg:col-span-5 gap-4">
              <div className={s.legalLinkList}>
                <Link
                  legacyBehavior
                  href={'/' + changeCase(t('imprint'), true)}
                >
                  <a className={s.link}>{t('imprint')}</a>
                </Link>
                <Link
                  legacyBehavior
                  href={'/' + language + '/' + changeCase(t('data_protection'), true)}
                >
                  <a className={s.link}>{t('data_protection')}</a>
                </Link>
                <Link
                  legacyBehavior
                  href={'/' + language + '/' + changeCase(t('contact'), true)}
                >
                  <a className={s.link}>{t('contact')}</a>
                </Link>
                <Link
                  legacyBehavior
                  href={'/' + language + '/' + changeCase(t('zahlung_und_versand_slug'), true)}
                >
                  <a className={s.link}>{t('zahlung_und_versand')}</a>
                </Link>
              </div>
              <div className="text-xxs text-center md:text-left text-green-5 opacity-50 hover:opacity-100 transition-opacity cursor-default"
                   dangerouslySetInnerHTML={{
                     __html: t('hinweis-footer'),
                   }}>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    </>
  )
}

// Sort pages by the sort order assigned in the BC dashboard
function bySortOrder(a: Page, b: Page) {
  return (a.sort_order ?? 0) - (b.sort_order ?? 0)
}

export default Footer
