import useSearch from "@vercel/commerce/product/use-search";
import { getAllProductsQuery, getCollectionProductsQuery, getSearchVariables, normalizeProduct } from "../utils";
export default useSearch;
export const handler = {
    fetchOptions: {
        query: getAllProductsQuery
    },
    async fetcher ({ input , options , fetch  }) {
        const { categoryId , brandId  } = input;
        const method = options == null ? void 0 : options.method;
        const variables = getSearchVariables(input);
        let products;
        // change the query to getCollectionProductsQuery when categoryId is set
        if (categoryId) {
            var _data_node, _data_node_products, _data_node_products_edges, _data_node1, _data_node_products1;
            const data = await fetch({
                query: getCollectionProductsQuery,
                method,
                variables
            });
            // filter on client when brandId & categoryId are set since is not available on collection product query
            products = brandId ? (_data_node = data.node) == null ? void 0 : (_data_node_products = _data_node.products) == null ? void 0 : (_data_node_products_edges = _data_node_products.edges) == null ? void 0 : _data_node_products_edges.filter(({ node: { vendor  }  })=>vendor.replace(/\s+/g, "-").toLowerCase() === brandId) : (_data_node1 = data.node) == null ? void 0 : (_data_node_products1 = _data_node1.products) == null ? void 0 : _data_node_products1.edges;
        } else {
            var _data_products;
            const data = await fetch({
                query: options.query,
                method,
                variables
            });
            products = (_data_products = data.products) == null ? void 0 : _data_products.edges;
        }
        return {
            products: products == null ? void 0 : products.map(({ node  })=>normalizeProduct(node)),
            found: !!(products == null ? void 0 : products.length)
        };
    },
    useHook: ({ useData  })=>(input = {})=>{
            return useData({
                input: [
                    [
                        "search",
                        input.search
                    ],
                    [
                        "categoryId",
                        input.categoryId
                    ],
                    [
                        "brandId",
                        input.brandId
                    ],
                    [
                        "sort",
                        input.sort
                    ],
                    [
                        "locale",
                        input.locale
                    ]
                ],
                swrOptions: {
                    revalidateOnFocus: false,
                    ...input.swrOptions
                }
            });
        }
};
