import { useHook, useMutationHook } from "../../utils/use-hook";
import { mutationFetcher } from "../../utils/default-fetcher";
export const fetcher = mutationFetcher;
const fn = (provider)=>{
    var _provider_customer, _provider_customer_card;
    return (_provider_customer = provider.customer) == null ? void 0 : (_provider_customer_card = _provider_customer.card) == null ? void 0 : _provider_customer_card.useAddItem;
};
const useAddItem = (...args)=>{
    const hook = useHook(fn);
    return useMutationHook({
        fetcher,
        ...hook
    })(...args);
};
export default useAddItem;
